<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:43:43
-->
<template>
	<div class="manHourFillDeleteDetail">
		<common-title :title="'详情'" />
		<div class="manHourFillDeleteDetail-content">
			<!-- 详情 -->
			<div class="">
				<div class="common-table">
					<div class="common-table-row">
						<div class="common-table-col">
							<span class="common-table-label">活动ID</span>
							<el-input readonly="readonly" v-model="manHourFillDeleteDetail.id" placeholder="请输入"
								class="common-table-val" />
						</div>
						<div class="common-table-col">
							<span class="common-table-label">共建基地名称</span>
							<span
								class="common-table-text">{{manHourFillDeleteDetail.activityType == 1 ? '非共建基地活动': manHourFillDeleteDetail.comBaseName}}</span>
						</div>
					</div>
					<div class="common-table-row">
						<div class="common-table-col">
							<span class="common-table-label">活动名称</span>
							<el-input readonly="readonly" v-model="manHourFillDeleteDetail.activityName" placeholder="请输入"
								class="common-table-val" />
						</div>
						<div class="common-table-col">
							<span class="common-table-label">组织方</span>
							<el-input readonly="readonly" v-model="manHourFillDeleteDetail.organizer" placeholder="请输入"
								class="common-table-val" />
						</div>
					</div>
					<div v-if="manHourFillDeleteDetail.starTime&&manHourFillDeleteDetail.endTime" class="">
						<div class="common-table-row">
							<div class="common-table-col">
								<span class="common-table-label">活动时间</span>
								<div class="common-table-text">
									<div class="">
										<span
											class="common-table-date">{{dateFormat(manHourFillDeleteDetail.starTime)}} 至 {{dateFormat(manHourFillDeleteDetail.endTime)}}</span>
									</div>
								</div>
							</div>
							<div class="common-table-col">
								<span class="common-table-label">活动地址</span>
								<div class="common-table-text">{{manHourFillDeleteDetail.address}}</div>
							</div>
						</div>
					</div>
					<div class="common-table-row">
						<div class="common-table-col">
							<span class="common-table-label">活动详情</span>
							<quill-editor :options="editorOption" class="common-table-val" :style="{'height': '400px'}"
								v-model="manHourFillDeleteDetail.activityDetails" @focus="editorFocus($event)">
							</quill-editor>
						</div>
					</div>
					<div class="common-table-row">
						<div class="common-table-col">
							<span class="common-table-label">审核信息</span>
							<div style="display: flex;flex-direction: column;" class="common-table-text">
								<div style="text-align: left;width: 100%;">是否通过:
									<span
										:style="{'color': manHourFillDeleteDetail.activityStatus > 1 ?'#1CABB9':''}">{{manHourFillDeleteDetail.activityStatus == 0?'审核不通过':manHourFillDeleteDetail.activityStatus == 1?'待审核':'审核通过'}}</span>
								</div>
								<div style="text-align: left;width: 100%;">审核意见: {{manHourFillDeleteDetail.auditOpinion}}</div>
								<!-- <div v-if="manHourFillDeleteDetail.deduction.includes('其他原因')"
									style="text-align: left;width: 100%;">扣分选项:
									<span>其他原因：{{manHourFillDeleteDetail.reasonsDetails}}</span>
								</div> -->
								<div v-if="manHourFillDeleteDetail.hasOther"
									style="text-align: left;width: 100%;">扣分选项:
									<span>其他原因：{{manHourFillDeleteDetail.reasonsDetails}}</span>
								</div>
								<div v-else style="text-align: left;width: 100%;">扣分选项: <span
										v-for="(item, index) in manHourFillDeleteDetail.deduction"
										:key="index">{{item}}, </span></div>
							</div>
						</div>
					</div>
					<div class="common-table-row manhour-confirm">
						<div class="common-table-col">
							<span class="common-table-label">工时认定</span>
							<div class="common-row-time" style="border-top: 0;">
								<div class="row-time-val">
									<div class="time-val-item">
										<span class="val-item-label">核定活动人数上限</span>
										<el-input readonly="readonly" v-model="manHourFillDeleteDetail.maxNumPeople"
											class="val-item-ipt" placeholder="请输入" />
									</div>
									<div class="time-val-item">
										<span class="val-item-label">核定单人工时数上限</span>
										<el-input readonly="readonly" v-model="manHourFillDeleteDetail.maxOneWorkingHours"
											class="val-item-ipt" placeholder="请输入" />
									</div>
									<div class="time-val-item">
										<span class="val-item-label">核定活动总工时数上限</span>
										<el-input readonly="readonly" v-model="manHourFillDeleteDetail.maxWorkingHours"
											class="val-item-ipt" placeholder="请输入" />
									</div>
								</div>
								<div class="row-time-mark">
									<span class="time-mark-label">注：</span>
									<div class="time-mark-content">
										<span>核定活动人数上限指该活动的可参加的总人数的上限。（限整数）</span>
										<span>核定核定单人工时数上限指该活动单人可获得的工时数上限。（限整数/一位小数）</span>
										<span>核定活动总工时数上限指该活动的最终生成工时表的所有志愿者工时数之和。（限整数/一位小数）</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- <div class="common-table-row">
						<div class="common-table-col">
							<span class="common-table-label">活动总人数</span>
							<span class="common-table-text">{{manHourFillDeleteDetail.maxNumPeople}}</span>
						</div>
						<div class="common-table-col">
							<span class="common-table-label">活动总工时数</span>
							<span class="common-table-text">{{manHourFillDeleteDetail.maxWorkingHours}}</span>
						</div>
					</div> -->
				</div>
			</div>
			<!-- 工时表 -->
			<div class="">
				<div class="manHourFillDeleteDetail-title">
					<span>工时表</span>
					<div v-if="manHourFillDeleteDetail.workingHoursList&&manHourFillDeleteDetail.workingHoursList.length > 0"
						@click="actionFn('deleteManHourExcel')" class="manHourFillDeleteDetail-title-btn">
						<img src="@/assets/images/common/delete.png">
						<span>删除工时表</span>
					</div>
				</div>
				<div class="manHourFillDeleteDetail-table">
					<el-table style="width: 100%" :data="manHourFillDeleteDetail.workingHoursList" border>
						<el-table-column prop="regNum" label="注册号">
						</el-table-column>
						<el-table-column prop="studentId" label="学号">
						</el-table-column>
						<el-table-column prop="name" label="姓名">
						</el-table-column>
						<el-table-column prop="gender" label="性别">
							<template slot-scope="scope">
								<span>{{scope.row.gender == true?"男":"女"}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="collegeName" label="学院班级">
							<template slot-scope="scope">
								<span>{{scope.row.collegeName}}{{scope.row.faculty}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="workingHours" label="工时数">
						</el-table-column>
						<el-table-column prop="action" label="操作">
							<template slot-scope="scope">
								<div class="table-action">
									<span @click="actionFn('deleteManHour', scope)">删除</span>
								</div>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getActivityDetail,
	} from "@/api/activity";
	import {
		detailManhour,
		deleteManhour,
		deleteManhourExcel,
	} from "@/api/manhour";
	import {
		getEnumKeyList
	} from "@/api/index.js";
	import {
		recruitFacultyList,
	} from "@/api/recruit";
	export default {
		data() {
			return {
				manHourFillDeleteDetail: {},
				activityStatus: 1,
				detailId: "",
				derictionList: [],
				editorOption: {
					placeholder: "暂无内容......",
				}
			};
		},
		watch: {},
		async created() {
			await this.getRecruitFacultyList()
			this.detailId = this.$route.query.detailId
			this.manHourFillDeleteDetailFn()
		},
		beforeRouteLeave(to,from,next){
			to.meta.isAlive = true
			next()
		},
		methods: {
			dateFormat(intDate) {
				if (intDate) {
					return intDate.replace(" 00:00:00", "")
				}
			},
			hasOtherFn(intArr) {
				if (intArr && intArr.length > 0) {
					if (intArr.includes('其他原因') || intArr.includes('其他')) {
						return true
					} else {
						return false
					}
				} else {
					return false
				}
			},
			editorFocus(e) {
				e.enable(false);
			},
			deductionMap(intVal) {
				let mapArr = this.deductionList.filter(item => item.key == intVal)
				return mapArr[0].value
			},
			derictionMap(intVal) {
				if (intVal) {
					let newArr = intVal.split(",")
					let resArr = []
					newArr.forEach(item => {
						this.derictionList.forEach(citem => {
							if (item == citem.id) {
								resArr.push(citem.name)
							}
						})
					})
					return resArr.join(",")
				} else {
					return ""
				}
			},
			getRecruitFacultyList() {
				return new Promise((resove, reject) => {
					let params = {
						pageIndex: 1,
						pageSize: 999,
					};
					recruitFacultyList(params).then((res) => {
						if (res.code == 0) {
							this.derictionList = res.data
							resove()
						} else {
							reject()
						}
					})
				})
			},
			getDeductionList() {
				return new Promise((resove, reject) => {
					getEnumKeyList({
						enumKey: "AUDIT_REASONS"
					}).then((res) => {
						if (res.code == 0) {
							this.deductionList = res.data
							resove()
						} else {
							reject()
						}
					})
				})
			},
			// 获取活动详情
			manHourFillDeleteDetailFn() {
				getActivityDetail(this.detailId, {
					id: this.detailId
				}).then((res) => {
					if (res.code == 0) {
						if (res.data.deduction) {
							res.data.deduction = res.data.deduction.split(",")
							let hasOther = this.hasOtherFn(res.data.deduction)
							this.$set(res.data, "hasOther", hasOther)
						} else {
							res.data.deduction = []
							this.$set(res.data, "hasOther", false)
						}
						this.manHourFillDeleteDetail = res.data
					}
				})
			},
			actionFn(type, scope) {
				switch (type) {
					case "deleteManHour":
						this.$confirm('确定删除此工时?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'error'
						}).then(() => {
							deleteManhour(scope.row.id, {
								id: scope.row.id
							}).then((res) => {
								if (res.code == 0) {
									this.$message({
										type: 'success',
										message: '删除成功!'
									});
									this.manHourFillDeleteDetailFn()
								}else{
									this.$message({
										type: 'error',
										message: res.msg,
									});
								}
							})
						}).catch(() => {});
						break;
					case "deleteManHourExcel":
						this.$confirm('确定删除此工时表?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'error'
						}).then(() => {
							deleteManhourExcel(this.manHourFillDeleteDetail.id, {
								id: this.manHourFillDeleteDetail.id
							}).then((res) => {
								this.$message({
									type: 'success',
									message: '删除成功!'
								});
								this.manHourFillDeleteDetailFn()
							})
						}).catch(() => {});
						break;
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	/deep/.el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 0;
	}

	/deep/.common-table-row .el-textarea__inner {
		resize: none;
		height: 400px;
		border: 0;
	}

	.manHourFillDeleteDetail {

		.manHourFillDeleteDetail-content {
			padding: 0 20px 20px 20px;

			.manHourFillDeleteDetail-step {
				display: flex;
				flex-direction: column;
				padding: 0 75px;
				margin: 20px 0;

				.manHourFillDeleteDetail-step-top {
					display: flex;
					align-items: center;
					padding: 0 5px;

					.manHourFillDeleteDetail-step-circle {
						width: 32px;
						height: 32px;
						line-height: 32px;
						background: #FFFFFF;
						border: 1px solid #B4BCC6;
						border-radius: 50%;
						text-align: center;
						font-size: 16px;
						font-family: PingFang SC;
						font-weight: bold;
						color: #B4BCC6;
					}

					.step-circle-green {
						border-color: $theme-color !important;
						color: $theme-color !important;
					}

					.step-circle-red {
						border-color: #E63757 !important;
						color: #E63757 !important;
					}

					.step-line-green {
						background-color: $theme-color !important;
					}

					.step-line-red {
						background-color: #E63757 !important;
					}

					.manHourFillDeleteDetail-step-line {
						flex: 1;
						height: 2px;
						background: #DCDFE6;
					}
				}

				.manHourFillDeleteDetail-step-bottom {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-top: 10px;

					span {
						font-size: 14px;
						font-family: PingFang SC;
						font-weight: 400;
						line-height: 20px;
						color: #B4BCC6;
					}
				}
			}

			.manHourFillDeleteDetail-title {
				font-size: 16px;
				font-family: PingFang SC;
				font-weight: bold;
				line-height: 22px;
				color: #1C2233;
				margin: 20px 0 10px 0;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.manHourFillDeleteDetail-title-btn {
					display: flex;
					align-items: center;
					cursor: pointer;

					img {
						width: 16px;
						height: 16px;
						margin-right: 4px;
					}

					span {
						font-size: 14px;
						font-family: PingFang SC;
						font-weight: 400;
						line-height: 20px;
						color: #606266;
					}
				}
			}
		}
	}
</style>
